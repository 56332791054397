import { Model } from '@vuex-orm/core'
import Project from './Project'
import TagPart from './TagPart'

export default class TagConvention extends Model {
  static entity = 'tagConvention'
  static url = "/tagConvention"
  static friendlyName = "Tag convention"

  static primaryKey = ['tagPartId', 'projectId']

  static fields () {
    return {
      index: this.attr(null),
      padding: this.attr(null),
      charAt: this.attr(null),
      delimiter: this.attr(null),
      tagPart: this.belongsTo(TagPart, "tagPartId"),
      tagPartId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      projectId: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
    }
  }
}