import { Model } from "@vuex-orm/core";
import Project from "./Project";
import Instrument from "./Instrument";
import InstrumentTypeInstrumentCollection from "./InstrumentTypeInstrumentCollection";
import ProcessFunction from "./ProcessFunction";
import InstrumentDatasheet from "./InstrumentDatasheet";
import InstrumentCollection from "./InstrumentCollection";

export default class InstrumentType extends Model {
  static entity = "instrumentTypes";
  static url = "/instrumentType";
  static friendlyName = "Instrument type";

  static fields() {
    return {
      id: this.attr(0),
      tag: this.attr(null),
      name: this.attr(null),
      isValve: this.attr(null),
      category: this.attr(null),
      ioType: this.attr(null),
      isParentInstrumentType: this.boolean(null),
      attributes: this.attr(null),
      processFunctionId: this.attr(null),
      processFunction: this.belongsTo(ProcessFunction, "processFunctionId"),
      instrumentDatasheetId: this.attr(null),
      instrumentDatasheet: this.belongsTo(InstrumentDatasheet, "instrumentDatasheetId"),
      //instrumentCollections: this.belongsToMany(InstrumentCollection, InstrumentTypeInstrumentCollection, 'InstrumentCollectionId', 'InstrumentTypeInstrumentCollectionId'),      instruments: this.hasMany(Instrument, "instrumentTypeId"),
      lastChangedBy: this.attr(null),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
    };
  }

  static beforeDelete(model) {
    InstrumentTypeInstrumentCollection.delete((instrumentCollectionInstrumentType) => {
      return instrumentCollectionInstrumentType.instrumentTypeId === model.id;
    });
  }
}
