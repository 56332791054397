import User from "../models/User";
import graph from "../plugins/graph";
import { camelCase, startCase } from "lodash";
import ved from "../plugins/ved";
import General from "../models/General";

class WebSocketService {
  constructor() {
    this.websocket = null;
    // this.url = "ws://localhost:3001"; // Adjust the WebSocket URL
    // this.url = "wss://ws-ved-prod.azurewebsites.net"
    const VED_WEBSOCKET_URL = General.VED_WEBSOCKET_URL;
    this.url = VED_WEBSOCKET_URL;
    this.connected = false;
    this.reconnectDelay = 1000; // Initial reconnect delay in milliseconds
  }

  connect() {
    this.websocket = new WebSocket(this.url);

    this.websocket.onopen = () => {
      console.log("WebSocket connected");
      this.connected = true;
      // Reset reconnect delay when successfully connected
      this.reconnectDelay = 1000;

      General.insertOrUpdate({
        data: {
          id: 1,
          isConnected: true,
        },
      });

      // Send an initial message after the connection is established
      const store = User.store();

      const introduceData = {
        topic: "introduce",
        userName: store.state.entities.users.user.username,
        projectId: store.state.entities.projects.selectedProjectId,
      };
      this.send(introduceData);
    };

    this.websocket.onmessage = async (event) => {
      const data = JSON.parse(event.data);
      //console.log("WebSocket message received:", data);

      // Handle incoming WebSocket messages here.
      if (data.topic === "userConnected") {
        for (const user of data.users) {
          //console.log(`Users connected: ${user.userName}`);
          const userName = user.userName;

          let response = await graph.searchUsers(userName);

          if (response !== null && response.value) {
            let userToAdd = {};
            userToAdd.id = response.value[0].id;
            userToAdd.online = true;
            userToAdd.name = response.value[0].displayName;
            userToAdd.jobTitle = response.value[0].jobTitle;
            userToAdd.username = response.value[0].userPrincipalName;
            userToAdd.graphPhoto = null;
            const graphPhoto = await graph.getUserPhoto(userName);
            if (graphPhoto) {
              userToAdd.graphPhoto = graphPhoto;
            }

            User.insertOrUpdate({
              data: userToAdd,
            });
          }
        }
      } else if (data.topic === "userDisconnected") {
        //console.log(`User disconnected: ${data.user}`);

        User.update({
          where: (user) => {
            return user.username === data.user;
          },

          data: { online: false },
        });
      } else if (data.topic === "updateEvent") {
        const store = User.store();
        const modelName = startCase(camelCase(data.model)).replace(/ /g, "");
        const model = await import(`../models/${modelName}.js`);

        if (data.lastChangedBy !== store.state.entities.users.user.username) {
          if (Array.isArray(data.id)) {
            for (const id of data.id) {
              ved.get(model.default, id);
            }
          } else {
            const id = data.id;
            ved.get(model.default, id);
          }
        } else {
          // console.log("No update needed")
        }
      } else if (data.topic === "deleteEvent") {
        const modelName = startCase(camelCase(data.model)).replace(/ /g, "");
        const model = await import(`../models/${modelName}.js`);
        const ids = data.id;

        if (Array.isArray(ids)) {
          if (modelName === "PidLine" || modelName === "InstrumentTypeInstrumentCollection") {
            model.default.delete(ids);
          } else {
            for (const id of ids) {
              if (Array.isArray(id)) {
                model.default.delete([id[0], id[1]]);
              } else {
                model.default.delete(id);
              }
            }
          }

        } else {
          model.default.delete(ids);
        }
      } else if (data.topic === "announceVedAppBuildNumber") {
        console.log(data.vedAppBuildNumber);
        console.log(General.buildNumber);

        General.insertOrUpdate({
          data: {
            id: 1,
            appBuildNumber: data.vedAppBuildNumber,
          },
        });
      }
    };

    this.websocket.onclose = (event) => {
      console.log(`WebSocket connection closed with code ${event.code}: ${event.reason}`);
      this.connected = false;
      const store = User.store();

      // Automatically attempt to reconnect with exponential backoff
      if (
        store.state.entities.projects.selectedProjectId !== 0 &&
        (event.code !== 1000 || event.code !== 1005)
      ) {
        General.insertOrUpdate({
          data: {
            id: 1,
            isConnected: false,
          },
        });
        setTimeout(() => {
          this.reconnect();
        }, this.reconnectDelay);

        // Exponential backoff: Double the delay for the next attempt
        this.reconnectDelay *= 2;
      }
    };
  }

  reconnect() {
    console.log(`Reconnecting in ${this.reconnectDelay / 1000} seconds...`);
    setTimeout(() => {
      this.connect();
    }, this.reconnectDelay);
  }

  send(message) {
    if (this.connected) {
      this.websocket.send(JSON.stringify(message));
    }
  }

  disconnect() {
    if (this.websocket) {
      this.websocket.close();
    }
  }
}

export default WebSocketService;
