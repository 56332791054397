import * as Sentry from "@sentry/vue";

export function handleApiError(err) {
  console.error(err);

  const name = err?.response?.data?.error;  
  const message = err?.response?.data?.details;
  const parent = err?.response?.data?.parent;

  switch (name) {
    case "Validation Error":
      console.log("Validation Error", err);
      Sentry.captureException(err);
      return new Error(`Validation Error: ${message.join(", ")} ${parent ? '\n Details: ' + parent : ''}`);

    case "Unique Constraint Error":
      console.log("Unique Constraint Error");
      Sentry.captureException(err);
      return new Error(`Validation Error: ${message.join(", ")} ${parent ? '\n Details: ' + parent : ''}`);

    case "Foreign Key Constraint Error":
      console.log("Foreign Key Constraint Error");
      Sentry.captureException(err);
      return new Error(`Foreign Key Constraint Error: ${message}`);

    case "Database Error":
      console.log("Database Error");
      Sentry.captureException(err);
      return new Error(`Database Error: ${message}`);

    case "Connection Error":
      console.log("Connection Error");
      Sentry.captureException(err);
      return new Error(`Connection Error: ${message}`);

    case "Request Error":
      console.log("Request Error");
      Sentry.captureException(err);
      return new Error(`Request Error: ${message}`);

    case "Base Error":
      console.log("Sequelize Error");
      Sentry.captureException(err);
      return new Error(`Sequelize Error: ${message}`);

    default:
      // Handle other errors
      Sentry.captureException(err);
      const errorMessage = message || "An unknown error occurred";
      return new Error(`General Error: ${message || err?.message || "An unknown error occurred"}`);
  }
}
