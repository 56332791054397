import { Model } from '@vuex-orm/core'
import Project from './Project'
// import Pid from './Pid'

export default class InstrumentTypeInstrumentCollection extends Model {
  static entity = 'instrumentTypeInstrumentCollection'
  static url = "/instrumenttypeinstrumentcollection"

  static primaryKey = ['instrumentTypeId', 'instrumentCollectionId']

  static fields () {
    return {
      instrumentTypeId: this.attr(null),      
      instrumentCollectionId: this.attr(null),
      sequenceNumber: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),
      //pid: this.belongsTo(Pid, "pidId"),
    }
  }
}