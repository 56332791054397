import { Database } from '@vuex-orm/core'
import User from '@/models/User'
import General from '@/models/General'

import Office from '@/models/Office'
import Client from '@/models/Client'
import ApplicableDirective from'@/models/ApplicableDirective'
import Schedule from '@/models/Schedule'
import PipeSize from'@/models/PipeSize'
import Project from '@/models/Project'
import ApplicationSettings from '@/models/ApplicationSettings'

import Insulation from'@/models/Insulation'
import Tracing from'@/models/Tracing'
import Medium from'@/models/Medium'

import Plant from '@/models/Plant'
import Area from '@/models/Area'
import Unit from '@/models/Unit'

import Milestone from '@/models/Milestone'
import PipeSizeRangeSchedule from '@/models/PipeSizeRangeSchedule'
import PipeSpec from '@/models/PipeSpec'
import ProcessFunction from '@/models/ProcessFunction'

import Isometric from '@/models/Isometric'
import Pid from '@/models/Pid'
import Service from '@/models/Service'
import Line from '@/models/Line'
import PidLine from '@/models/PidLine'
import PipeWallThickness from '../models/PipeWallThickness'
import EquipmentType from '../models/EquipmentType'
import InstrumentCollection from '../models/InstrumentCollection'
import InstrumentType from '../models/InstrumentType'
import InstrumentInstrumentTypeCollection from '../models/InstrumentTypeInstrumentCollection'
import Equipment from '../models/Equipment'
import Instrument from '../models/Instrument'
import InstrumentDatasheet from '../models/InstrumentDatasheet'
import PressureRating from '../models/PressureRating'
import Valve from '../models/Valve'
import ValveSpec from '../models/ValveSpec'
import TagConvention from '../models/TagConvention'
import TagPart from '../models/TagPart'
import SubProject from '../models/SubProject'
import TableState from '../models/TableState'


const database = new Database()

database.register(ApplicableDirective)
database.register(ApplicationSettings)
database.register(Area)
database.register(Client)
database.register(Equipment)
database.register(EquipmentType)
database.register(General)
database.register(Instrument)
database.register(InstrumentDatasheet)
database.register(InstrumentType)
database.register(InstrumentCollection)
database.register(InstrumentInstrumentTypeCollection)
database.register(Insulation)
database.register(Isometric)
database.register(Line)
database.register(Medium)
database.register(Milestone)
database.register(Office)
database.register(PidLine)
database.register(Pid)
database.register(PipeSize)
database.register(PipeSizeRangeSchedule)
database.register(PipeSpec)
database.register(PipeWallThickness)
database.register(Plant)
database.register(PressureRating)
database.register(ProcessFunction)
database.register(Project)
database.register(Schedule)
database.register(Service)
database.register(SubProject)
database.register(TableState)
database.register(TagConvention)
database.register(TagPart)
database.register(Tracing)
database.register(Unit)
database.register(User)
database.register(Valve)
database.register(ValveSpec)










// console.log(database.schemas)


export default database