import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import General from "./models/General";
import vuetify from "./plugins/vuetify";
import * as Sentry from "@sentry/vue";

Sentry.setTag("app_version", process.env.PACKAGE_VERSION || "0");
Sentry.setTag("app_buildnumber", process.env.VERSION || "0");

Vue.config.productionTip = false;
// Vue.prototype.$msalInstance = {};
// Vue.prototype.$emitter = new Emitter();

////const options = {transports: ["websocket", "polling", "flashsocket"] }; //Options object to pass into SocketIO
// const socketConnection = SocketIO('http://localhost:3000', options);

if (process.env.SENTRY_AUTH_TOKEN) {
  Sentry.init({
    Vue,
    dsn: "https://6d319e75dc244b679ed2a047e621e2a3@o4505144855101440.ingest.sentry.io/4505144857001984",
    integrations: [
      new Sentry.BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    release: process.env.PACKAGE_VERSION || "0",
    tracesSampleRate: 0.2,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
