<template>
  <v-app>
    <!-- <v-system-bar v-if="user && project" app height="20" color="primary">
      <v-spacer></v-spacer>
      <v-icon :color="user.online ? 'green' : 'red'">mdi-web</v-icon>
    </v-system-bar> -->

    <v-app-bar v-if="user && project" app clipped-left color="primary" dense dark>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-toolbar-title>Vicoma Engineering Database</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn dark icon @click="toggleDarkLightTheme()">
        <v-icon>mdi-theme-light-dark</v-icon>
      </v-btn>

      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn dark icon v-bind="attrs" v-on="on">
            <v-icon>mdi-dots-horizontal</v-icon>
          </v-btn>
        </template>

        <v-list dense>
          <v-list-item
            v-for="(item, i) in mainMenuItems"
            :key="i"
            @click="handleMenuItemClick(item)"
          >
            <v-list-item-icon>
              <v-icon v-if="item.icon"> {{ item.icon }} </v-icon>
            </v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-menu bottom min-width="300px" offset-y rounded>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" icon x-large>
            <v-avatar color="accent" size="36">
              <img v-if="user.graphPhoto !== null" :src="user.graphPhoto" />
              <span v-else>{{ user.username }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card class="text-center">
          <v-list>
            <v-list-item class="d-flex justify-center mt-2">
              <v-list-item-avatar color="accent" size="120">
                <img v-if="user.graphPhoto !== null" :src="user.graphPhoto" />
                <span v-else>{{ user.username }}</span>
              </v-list-item-avatar>
            </v-list-item>
            <v-list-item>
              <v-list-item-content class="justify-center">
                <h3>{{ user.name }}</h3>
                <p class="caption mt-1">
                  {{ user.username }}
                </p>
                <p class="caption mt-1">v{{ appVersion }} (build {{ buildNumber }})</p>
                <v-divider class="my-3"></v-divider>
                <v-btn depressed text @click="fullLogout"> Sign out </v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer v-if="user && project" v-model="drawer" app clipped>
      <v-list dense nav>
        <div v-for="(item, index) in drawerItems" :key="index">
          <v-list-item v-if="!item.subItems" :to="item.to" link>
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-group v-else :key="item.title" :prepend-icon="item.icon" :value="false">
            <template v-slot:activator>
              <v-list-item-content>
                <v-list-item-title>{{ item.title }}</v-list-item-title>
              </v-list-item-content>
            </template>

            <div v-for="subItem in item.subItems" :key="subItem.title">
              <v-list-item v-if="!subItem.subItems" :to="subItem.to" link>
                <v-list-item-icon>
                  <v-icon class="pl-4" small>{{ subItem.icon }}</v-icon>
                </v-list-item-icon>
                <v-list-item-title>{{ subItem.title }}</v-list-item-title>
              </v-list-item>

              <v-list-group no-action sub-group v-else :key="subItem.title" :value="false">
                <template v-slot:prependIcon>
                  <v-icon small> {{ subItem.icon }} </v-icon>
                </template>
                <template v-slot:activator>
                  <!-- <v-icon small>{{subItem.icon}}</v-icon> -->
                  <v-list-item-content>
                    <v-list-item-title>{{ subItem.title }}</v-list-item-title>
                  </v-list-item-content>
                  <!-- <v-list-item-icon>
                  <v-icon small>{{subItem.icon}}</v-icon>
                </v-list-item-icon> -->
                </template>
                <div v-for="secondLevelItem in subItem.subItems" :key="secondLevelItem.title">
                  <v-list-item :to="secondLevelItem.to" link>
                    <v-list-item-icon>
                      <!-- <v-icon>{{subItem.icon}}</v-icon> -->
                    </v-list-item-icon>
                    <v-list-item-title>{{ secondLevelItem.title }}</v-list-item-title>
                  </v-list-item>
                </div>
              </v-list-group>
            </div>
          </v-list-group>
        </div>
      </v-list>
    </v-navigation-drawer>

    <v-main class="lightbackground">
      <v-container :fill-height="!project" fluid>
        <router-view v-if="project"></router-view>

        <v-row v-else align="center" justify="center">
          <v-btn v-if="!user && !error" color="primary" @click="doLogin"> Login </v-btn>
          <v-card v-else class="mt-4">
            <v-app-bar dense flat color="secondary">
              <v-toolbar-title class="white--text pl-0"> Select project </v-toolbar-title>

              <v-spacer></v-spacer>

              <v-menu bottom left>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn dark icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list dense>
                  <v-list-item
                    v-for="(item, i) in projectMenuItems"
                    :key="i"
                    @click="handleMenuItemClick(item)"
                  >
                    <v-list-item-icon>
                      <v-icon v-if="item.icon"> {{ item.icon }} </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-app-bar>

            <v-card-text>
              <v-autocomplete
                clearable
                v-model="project"
                :items="projects"
                item-text="title"
                item-value="id"
                label="Project"
                :loading="projects.length === 0"
                outlined
                dense
              >
                <template v-slot:item="{ item }">
                  {{ item.title }}
                </template>
              </v-autocomplete>
            </v-card-text>
            <v-dialog v-model="newProjectDialog" max-width="50%">
              <v-card>
                <v-app-bar dense flat color="secondary">
                  <v-toolbar-title class="text-h7 white--text pl-0"> New project </v-toolbar-title>

                  <v-spacer></v-spacer>
                </v-app-bar>

                <v-form ref="newProjectForm" v-model="valid" lazy-validation>
                  <v-card-text>
                    <v-container>
                      <v-row dense>
                        <v-col cols="12" sm="2" md="2">
                          <v-select
                            :rules="[rules.required]"
                            :items="offices"
                            item-value="id"
                            item-text="code"
                            label="Office"
                            v-model="newProject.officeId"
                          >
                            <template v-slot:selection="{ item }">
                              {{ item.code }}
                            </template>
                            <template v-slot:item="{ item }">
                              <v-row class="table-select" no-gutters>
                                <v-col text-title md="3">{{ item.name }}</v-col>
                              </v-row>
                            </template>
                          </v-select>
                        </v-col>

                        <v-col cols="12" sm="10" md="10">
                          <v-text-field
                            :rules="[rules.required, rules.isNumber]"
                            v-model.number="newProject.number"
                            counter
                            maxlength="5"
                            label="Project number"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                          ></v-text-field>
                        </v-col>

                        <!-- <v-col cols="12" sm="6" md="6"> </v-col> -->

                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            :rules="[rules.required]"
                            label="Project name"
                            v-model="newProject.name"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                          >
                            <template v-slot:item="{ item }">
                              <v-row class="table-select" no-gutters>
                                <v-col text-title md="3">{{ item.name }}</v-col>
                              </v-row>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            :rules="[rules.required]"
                            :items="clients"
                            item-value="id"
                            item-text="name"
                            label="Client"
                            v-model="newProject.clientId"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            label="Description"
                            v-model="newProject.description"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                          >
                            <template v-slot:item="{ item }">
                              <v-row class="table-select" no-gutters>
                                <v-col text-title md="3">{{ item.description }}</v-col>
                              </v-row>
                            </template>
                          </v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-select
                            :rules="[rules.required]"
                            :items="[
                              { id: 1, name: 'NPS' },
                              { id: 2, name: 'DN' },
                            ]"
                            item-text="name"
                            item-value="id"
                            label="Default pipe size system"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                            v-model="newProject.pipeSizeSystem"
                          >
                          </v-select>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-text-field
                            readonly
                            outlined
                            label="Result"
                            :value="newProject.title"
                            :color="$vuetify.theme.dark ? 'vicyellow' : ''"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12">
                          <v-alert
                            :value="newProjectDialogAlertVisible"
                            dense
                            outlined
                            type="error"
                          >
                            {{ newProjectDialogAlertText }}
                          </v-alert>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="accent" text @click="closeDialog"> Cancel </v-btn>
                    <v-btn :disabled="!valid" color="accent" @click="newItem()"> Save </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-card>
        </v-row>
        <v-snackbar v-model="snack.show" :timeout="snack.timeout" :color="snack.color">
          {{ snack.text }}
          <template v-slot:action="{ attrs }"> </template>
        </v-snackbar>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Project from "@/models/Project";
import { LicenseManager } from "ag-grid-enterprise";
import Helpers from "./helpers/helpers";
import ApplicableDirective from "./models/ApplicableDirective";
// import ApplicationSettings from "./models/ApplicationSettings";
import Area from "./models/Area";
import Client from "./models/Client";
import Equipment from "./models/Equipment";
import EquipmentType from "./models/EquipmentType";
import General from "./models/General";
import Instrument from "./models/Instrument";
import InstrumentDatasheet from "./models/InstrumentDatasheet";
import InstrumentType from "./models/InstrumentType";
import InstrumentCollection from "./models/InstrumentCollection";
import InstrumentTypeInstrumentCollection from "./models/InstrumentTypeInstrumentCollection";
import Insulation from "./models/Insulation";
import Isometric from "./models/Isometric";
import Line from "./models/Line";
import Medium from "./models/Medium";
import Office from "./models/Office";
import Pid from "./models/Pid";
import PidLine from "./models/PidLine";
import PipeSize from "./models/PipeSize";
import PipeSizeRangeSchedule from "./models/PipeSizeRangeSchedule";
import PipeSpec from "./models/PipeSpec";
import PipeWallThickness from "./models/PipeWallThickness";
import Plant from "./models/Plant";
import PressureRating from "./models/PressureRating";
import ProcessFunction from "./models/ProcessFunction";
import Schedule from "./models/Schedule";
import Service from "./models/Service";
import TagPart from "./models/TagPart";
import Tracing from "./models/Tracing";
import Unit from "./models/Unit";
import User from "./models/User";
import Valve from "./models/Valve";
import ValveSpec from "./models/ValveSpec";
import auth from "./plugins/auth";
import graph from "./plugins/graph";
import ved from "./plugins/ved";
import WebSocketService from "./services/websocket.service.js";
import * as Sentry from "@sentry/vue";
import SubProject from "./models/SubProject";
import Milestone from "./models/Milestone";

// LicenseManager.setLicenseKey(
//   "CompanyName=Vicoma Beheer B.V.,LicensedApplication=VED,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-033377,SupportServicesEnd=27_September_2023_[v2]_MTY5NTc2OTIwMDAwMA==c0239b320b61140990a62702db10f976"
// );
LicenseManager.setLicenseKey(
  "Using_this_AG_Grid_Enterprise_key_( AG-045326 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( Vicoma Beheer B.V. )_is_granted_a_( Single Application )_Developer_License_for_the_application_( VED )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( VED )_need_to_be_licensed___( VED )_has_not_been_granted_a_Deployment_License_Add-on___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 26 September 2024 )____[v2]_MTcyNzMwNTIwMDAwMA==34c52e78a73ae186f9b1483aeba19887"
);

export default {
  components: {},

  data: function () {
    return {
      name: "App",
      drawer: null,
      tab: null,
      error: null,
      accessToken: "",
      graphDetails: null,
      userDetails: null,
      graphPhoto: null,
      snack: { show: false, color: "", text: "", timeout: 3000 },
      mainMenuItems: [
        {
          title: "Close project",
          icon: "mdi-close",
          action: "closeProject",
        },
        {
          title: "Visit VED team",
          icon: "mdi-microsoft-teams",
          action: "visitMsTeam",
        },
        {
          title: "VED videos",
          icon: "mdi-help",
          action: "visitMsStream",
        },
      ],
      projectMenuItems: [
        {
          title: "New project",
          icon: "mdi-plus",
          action: "newProjectDialog",
        },
      ],
      tabItems: [
        {
          tab: "General",
          id: "projectGeneral",
          to: "/project/general",
          category: "project",
        },
        {
          tab: "Plant",
          id: "projectPlant",
          to: "/project/plant",
          category: "projectPlant",
        },
        {
          tab: "Area",
          id: "projectArea",
          to: "/project/area",
          category: "projectPlant",
        },
        {
          tab: "Unit",
          id: "projectUnit",
          to: "/project/unit",
          category: "projectPlant",
        },
        {
          tab: "Services",
          id: "processServices",
          to: "/process/services",
          category: "process",
        },
        {
          tab: "Lines",
          id: "processLines",
          to: "/process/lines",
          category: "process",
        },
        {
          tab: "Valves",
          id: "processValves",
          to: "/process/valves",
          category: "process",
        },
        {
          tab: "Instruments",
          id: "processInstruments",
          to: "/process/instruments",
          category: "process",
        },
        {
          tab: "Equipment",
          id: "processEquipment",
          to: "/process/equipment",
          category: "process",
        },
        {
          tab: "Pipe specs",
          id: "pipingPipespecs",
          to: "/piping/pipespecs",
          category: "piping",
          // get headers() {
          //   return getTableColumns(this.id);
          // },
        },
        {
          tab: "Valve specs",
          id: "pipingValvespecs",
          to: "/piping/valvespecs",
          category: "piping",
        },
      ],
      drawerItems: [
        {
          title: "Home",
          icon: "mdi-home",
          to: "/",
          category: "home",
          // action: this.logout
        },
        {
          title: "Activity",
          icon: "mdi-bell-outline",
          to: "/activity",
          category: "activity",
        },
        {
          title: "Validation",
          icon: "mdi-check-decagram-outline",
          to: "/validation",
          category: "validation",
          // action: this.logout
        },
        {
          title: "Help",
          icon: "mdi-help-circle-outline",
          to: "/help",
          category: "help",
        },
        {
          title: "Project",
          icon: "mdi-view-dashboard",
          to: "/project/",
          category: "project",
          subItems: [
            {
              title: "Sub projects",
              to: "/project/sub-projects",
              icon: "",
              category: "projectSubProjects",
            },
            {
              title: "Plants",
              to: "/project/plants",
              icon: "",
              category: "projectPlants",
            },
            {
              title: "Mediums",
              to: "/project/mediums",
              icon: "",
              category: "projectMediums",
            },
            {
              title: "Insulation",
              to: "/project/insulations",
              icon: "",
              category: "projectInsulations",
            },
            {
              title: "Tracing",
              to: "/project/tracings",
              icon: "",
              category: "projectTracings",
            },
            {
              title: "Documents",
              to: "/project/documents",
              icon: "",
              category: "projectDocuments",
            },
            {
              title: "Instruments",
              to: "/project/instruments",
              icon: "",
              category: "projectInstruments",
            },
            {
              title: "Equipment types",
              to: "/project/equipment-types",
              icon: "",
              category: "projectEquipmentTypes",
            },
          ],
        },

        {
          title: "Specs & Services",
          icon: "mdi-ballot-outline",
          to: "/specs/service",
          category: "specs",
          subItems: [
            {
              title: "Pipe specs",
              to: "/specs/pipespecs",
              icon: "",
              category: "specsPipespecs",
            },
            {
              title: "Valve specs",
              to: "/specs/valvespecs",
              icon: "",
              category: "specsValvespecs",
            },
            {
              title: "Services",
              to: "/specs/services",
              icon: "",
              category: "specsServices",
            },
          ],
        },
        {
          title: "Process",
          icon: "mdi-flask-outline",
          to: "/process/service",
          category: "process",
          subItems: [
            {
              title: "Lines",
              to: "/process/lines",
              icon: "mdi-pipe",
              category: "processLines",
            },
            {
              title: "Instruments",
              to: "/process/instruments",
              icon: "mdi-sine-wave",
              category: "processInstruments",
            },
            {
              title: "Valves",
              to: "/process/valves",
              icon: "mdi-pipe-valve",
              category: "processValves",
            },
            {
              title: "Equipment",
              to: "/process/equipment",
              icon: "mdi-square-rounded-outline",
              category: "processEquipment",
              subItems: [
                {
                  title: "All",
                  to: "/process/equipment/all",
                  icon: "mdi-pipe",
                  category: "processEquipment",
                },
                {
                  title: "Tanks & vessels",
                  to: "/process/equipment/tanks",
                  icon: "mdi-pipe",
                  category: "processEquipment",
                },
                {
                  title: "Pumps & fans",
                  to: "/process/equipment/pumps",
                  icon: "mdi-pipe",
                  category: "processEquipment",
                },
                {
                  title: "Motor",
                  to: "/process/equipment/motors",
                  icon: "mdi-pipe",
                  category: "processEquipment",
                },
                {
                  title: "Heat exchanger",
                  to: "/process/equipment/heat-exchanger",
                  icon: "mdi-pipe",
                  category: "processEquipment",
                },
              ],
            },
          ],
        },
        {
          title: "Piping",
          icon: "mdi-pipe",
          to: "/piping/pipespecs",
          category: "piping",
          subItems: [
            {
              title: "Lines",
              to: "/piping/lines",
              icon: "mdi-pipe",
              category: "pipingLines",
            },
          ],
        },
        {
          title: "Instrumentation",
          icon: "mdi-sine-wave",
          to: "/instrumentation",
          category: "instrumentation",
          subItems: [
            {
              title: "Instruments",
              to: "/instrumentation/instruments",
              icon: "mdi-sine-wave",
              category: "instrumentationInstruments",
            },
            {
              title: "Valves",
              to: "/instrumentation/valves",
              icon: "mdi-pipe-valve",
              category: "instrumentationValves",
            },
            {
              title: "Loops",
              to: "/instrumentation/loops",
              icon: "mdi-transit-connection-variant",
              category: "instrumentationLoops",
            },            
          ],
        },
        // {
        //   title: "Electrical",
        //   icon: "mdi-flash",
        //   to: "/electrical",
        //   category: "electrical",
        // },
      ],
      right: null,
      newProject: new Project(),
      newProjectDialog: false,
      newProjectDialogAlertText: "",
      newProjectDialogAlertVisible: false,
      valid: true,
      currentProject: null,
      rules: {
        required: (value) => !!value || "Required.",
        selectRequired: (value) => value.id != 0 || "Required.",
        propertyRequired: (value) => value.path !== "" || "Required.",
        isNumber: (value) => (!isNaN(parseFloat(value)) && value > 0) || "Must be number > 0",
      },
      websocketService: null,
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.state.entities.users.user;
      },
      set(user) {
        User.commit((state) => {
          state.user = user;
        });
      },
    },
    project: {
      get() {
        return this.$store.state.entities.projects.selectedProjectId;
      },
      set(projectId) {
        Project.commit((state) => {
          state.selectedProjectId = projectId;
        });
      },
    },
    appVersion() {
      return General.appVersion;
    },
    buildNumber() {
      return General.buildNumber;
    },
    updatedBuildNumber() {
      return General.find(1)?.appBuildNumber || null;
    },
    isConnected() {
      return General.find(1)?.isConnected;
    },
    projects() {
      return Project.query().withAll().orderBy("number").get();
    },
    offices() {
      return Office.query().orderBy("code").get();
    },
    clients() {
      return Client.query().orderBy("name").get();
    },
  },
  async created() {
    // Basic setup of MSAL helper with client id, or give up
    if (process.env.VUE_APP_CLIENT_ID) {
      auth.configure(process.env.VUE_APP_CLIENT_ID, false);
      // Restore any cached or saved local user
      // console.log(auth.user());
      this.user = auth.user();
      if (this.user) {
        this.user.id = this.user.localAccountId;
        this.user.online = true;
        User.insertOrUpdate({
          data: this.user,
        });
      }
      console.log(`configured ${auth.isConfigured()}`);
    } else {
      console.log("VUE_APP_CLIENT_ID is not set, the app will not function! 😥");
    }

    // SocketioService.setupSocketConnection();
  },
  mounted() {},

  beforeUnmount() {
    console.log("App.vue unmount");
    //SocketioService.disconnect();
    this.webSocketService.disconnect();
  },

  watch: {
    // If user changes, make calls to Graph API
    user: async function () {
      console.log("Fetching graph details");
      if (this.user) {
        Sentry.setTag("vic_user_name", this.$store.state.entities.users.user.username || "0");
        this.fetchGraphDetails();
        console.log("Fetching general VED data");
        try {
          ved.getAll(Project);
          ved.getAll(Office);
          ved.getAll(Client);
          ved.getAll(ApplicableDirective);
          ved.getAll(PipeSize);
          ved.getAll(ProcessFunction);
          ved.getAll(Schedule);
        } catch (e) {
          this.snack.show = true;
          this.snack.color = "error";
          this.snack.timeout = -1;
          this.snack.text = e;
        }
      }
    },
    async project(newVal, oldVal) {
      if (newVal !== 0) {
        this.currentProject = newVal;
        console.log("Connecting to websocket...");
        // SocketioService.setupSocketConnection();
        this.websocketService = new WebSocketService();
        this.websocketService.connect(); // Connect to the WebSocket server

        if (this.user) {
          console.log("Fetching data from VED API");
          const start = performance.now();
          try {
            await Promise.allSettled([
              ved.getProjectUsers(this.currentProject),
              // ved.getAll(ApplicationSettings, this.currentProject),
              ved.getAll(Area, this.currentProject),
              ved.getAll(Equipment, this.currentProject),
              ved.getAll(EquipmentType, this.currentProject),
              ved.getAll(Instrument, this.currentProject),
              ved.getAll(InstrumentDatasheet, this.currentProject),
              ved.getAll(InstrumentType, this.currentProject),
              ved.getAll(InstrumentCollection, this.currentProject),
              ved.getAll(InstrumentTypeInstrumentCollection, this.currentProject),
              ved.getAll(Insulation, this.currentProject),
              ved.getAll(Isometric, this.currentProject),
              ved.getAll(Line, this.currentProject),
              ved.getAll(Medium, this.currentProject),
              ved.getAll(Milestone, this.currentProject),
              ved.getAll(Pid, this.currentProject),
              ved.getAll(PipeSizeRangeSchedule, this.currentProject),
              ved.getAll(PipeSpec, this.currentProject),
              ved.getAll(PipeWallThickness, this.currentProject),
              ved.getAll(Plant, this.currentProject),
              ved.getAll(PressureRating, this.currentProject),
              ved.getAll(Service, this.currentProject),
              ved.getAll(SubProject, this.currentProject),
              ved.getAll(TagPart, this.currentProject),
              ved.getAll(Tracing, this.currentProject),
              ved.getAll(Unit, this.currentProject),
              ved.getAll(Valve, this.currentProject),
              ved.getAll(ValveSpec, this.currentProject),
            ]).then((allPromises) => {
              const end = performance.now();
              console.log(`Fetched data in: ${end - start} ms`);
            });
          } catch (e) {
            this.snack.show = true;
            this.snack.color = "error";
            this.snack.timeout = -1;
            this.snack.text = e;
          }
        }
      }
    },
    updatedBuildNumber(newVal, oldVal) {
      if (newVal && newVal != this.buildNumber && this.buildNumber != 0) {
        console.log("New front end build available");
        this.snack.show = true;
        this.snack.color = "error";
        this.snack.timeout = -1;
        this.snack.text = `A new version (${this.updatedBuildNumber}) has been released. Please refresh your browser!`;
      }
    },
    isConnected(newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.snack.show = true;
        this.snack.color = "error";
        this.snack.timeout = -1;
        this.snack.text = `Connection lost. Reconnecting...`;
      } else if (newVal && oldVal === false) {
        this.snack.show = true;
        this.snack.color = "success";
        this.snack.timeout = 5000;
        this.snack.text = `Connection restored`;
      }
    },
  },

  methods: {
    async doLogin() {
      try {
        await auth.login();
        this.updateUser();
      } catch (err) {
        // console.log(err.toString());
      }
    },
    updateUser() {
      this.user = auth.user();
      this.user.id = this.user.localAccountId;
      this.user.online = true;
      User.insertOrUpdate({
        data: this.user,
      });
    },
    // Remove locally held user details, is same as logout
    shallowLogout() {
      this.user = null;
      this.graphDetails = null;
      this.userDetails = null;
      this.graphPhoto = null;
      auth.clearLocal();
    },
    // Full logout local & server side
    fullLogout() {
      auth.logout();
    },
    // Get an access token and call graphGetSelf & graphGetPhoto
    async fetchGraphDetails() {
      if (!this.user || this.user.username == "demo@example.net") {
        return;
      }
      try {
        this.user.graphDetails = await graph.getSelf();
        this.user.graphPhoto = await graph.getPhoto();
        this.accessToken = graph.getAccessToken();
      } catch (err) {
        // console.log(err.toString());
      }
    },
    async newItem() {
      if (this.$refs.newProjectForm.validate()) {
        try {
          // console.log(this.newProject);
          const response = await ved.create(Project, this.newProject);
          this.closeDialog();
        } catch (err) {
          this.handleErrorResponse(err);
        }
      }
    },
    handleMenuItemClick(item) {
      if (item.action === "closeProject") {
        this.project = 0;
        // SocketioService.disconnect();
        this.websocketService.disconnect();
        this.$store.dispatch("entities/deleteAll");
        try {
          ved.getAll(Project);
          ved.getAll(Office);
          ved.getAll(Client);
          ved.getAll(ApplicableDirective);
          ved.getAll(PipeSize);
          ved.getAll(ProcessFunction);
          ved.getAll(Schedule);
        } catch (e) {
          console.log(e);
          this.snack.show = true;
          this.snack.color = "error";
          this.snack.timeout = -1;
          this.snack.text = e;
        }
      } else if (item.action === "newProjectDialog") {
        this.newProjectDialog = true;
      } else if (item.action === "visitMsTeam") {
        window.open(
          "https://teams.microsoft.com/l/team/19%3a475a0c60c30744fdbe74bc308e09c1b2%40thread.skype/conversations?groupId=868d0c6a-021f-4a55-86bb-f7910110e94e&tenantId=7575366c-381e-4d01-a93b-11a16dbbb1f3",
          "_blank",
          "noreferrer"
        );
      } else if (item.action === "visitMsStream") {
        window.open(
          "https://web.microsoftstream.com/channel/9a2b36f8-bedf-4501-b731-974e02e2ea23",
          "_blank",
          "noreferrer"
        );
      }
    },
    closeDialog() {
      this.newProjectDialog = false;
      this.$refs.newProjectForm.resetValidation();
      this.newProjectDialogAlertVisible = false;
      this.newProject = new Project();
    },
    toggleDarkLightTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-background-color", "#1E1E1E")
        : document.body.style.setProperty("--ag-background-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-foreground-color", "#FFFFFF")
        : document.body.style.setProperty("--ag-foreground-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-secondary-foreground-color", "#FFFFFF")
        : document.body.style.setProperty("--ag-secondary-foreground-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-header-background-color", "#1E1E1E")
        : document.body.style.setProperty("--ag-header-background-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-header-foreground-color", "#FFFFFF")
        : document.body.style.setProperty("--ag-header-foreground-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-row-hover-color", "#DEC88F")
        : document.body.style.setProperty("--ag-row-hover-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-header-cell-hover-background-color", "")
        : document.body.style.setProperty("--ag-header-cell-hover-background-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-selected-row-background-color", "#D2B468")
        : document.body.style.setProperty("--ag-selected-row-background-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-subheader-background-color", "#009bff")
        : document.body.style.setProperty("--ag-subheader-background-color", "");
      this.$vuetify.theme.dark
        ? document.body.style.setProperty("--ag-subheader-toolbar-background-color", "#009bff")
        : document.body.style.setProperty("--ag-subheader-toolbar-background-color", "");

      //this.$vuetify.theme.dark ? document.body.style.setProperty('--ag-range-selection-background-color', '#009bff') : document.body.style.setProperty('--ag-range-selection-background-color', '#D2B468')

      // var(--ag-range-selection-background-color-1, var(--ag-range-selection-background-color, rgba(122, 134, 203, 0.1)))
    },
    handleErrorResponse(err) {
      this.newProjectDialogAlertText = err;

      if (this.newProjectDialog) {
        this.newProjectDialogAlertVisible = true;
      }
    },
  },
};
</script>

<style lang="scss">
$body-font-family: "Roboto";
$title-font: "Roboto";

// .ag-input-field-input.ag-text-field-input::placeholder {
//     color: white !important
//   }

.v-list-item--active.v-list-item.v-list-item--link.theme--dark {
  color: #d2b468 !important;
}

.v-application {
  font-family: $body-font-family, sans-serif !important;

  .title {
    font-family: $title-font, sans-serif !important;
  }
}
</style>
