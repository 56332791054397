import { Model } from '@vuex-orm/core'
import Project from "./Project";
import Instrument from './Instrument';
import InstrumentType from './InstrumentType';
import InstrumentTypeInstrumentCollection from './InstrumentTypeInstrumentCollection';  


export default class InstrumentCollection extends Model {
  static entity = 'instrumentCollection'
  static url = "/instrumentCollection"
  static friendlyName = "Instrument collection"

  static fields () {
    return {
      id: this.attr(0),
      name: this.attr(null),
      number: this.attr(null),
      description: this.attr(null),
      instrumentTypes: this.belongsToMany(InstrumentType, InstrumentTypeInstrumentCollection, "instrumentCollectionId", "instrumentTypeId"),  
      instruments: this.hasMany(Instrument, "instrumentCollectionId"),
      createdAt: this.attr(null),
      updatedAt: this.attr(null),
      projectId: this.attr(null),
      project: this.belongsTo(Project, "projectId"),      
    }
  }

  static beforeDelete (model) {
    InstrumentTypeInstrumentCollection.delete((instrumentCollectionInstrumentType) => {
      return instrumentCollectionInstrumentType.instrumentCollectionId === model.id
    })
  }

  // static afterUpdate (model) {
  //   InstrumentTypeInstrumentCollection.delete((instrumentCollectionInstrumentType) => {
  //     return instrumentCollectionInstrumentType.instrumentCollectionId === model.id
  //   })
  // }
}